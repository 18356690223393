<template>
    <!--    <el-dialog :visible="visible" width="800px" class="dialog" @close="handleClose">-->
    <div>
        <div>
            <div ref="print" style="padding: 20px;letter-spacing: 1px;font-family: 'SimSun', '宋体', Arial, sans-serif;"
                id="printTest">
                <el-row style="page-break-after:always">
                    <el-row class="text-title">
                        健康风险评估报告
                    </el-row>
                    <el-row class="item-jbxx">
                        <div class="item-list" v-for="(item, index) in jbxxi" :key="index">
                            <div class="label">
                                <div style="display: flex;justify-content: space-between">
                                    <span v-for="(it, ids) in item.label" :key="ids">{{ it }}</span>
                                </div>
                            </div>
                            <div class="label-value" v-if="item.fields == 'sex'">
                                <span v-if="form[item.fields] == '1'">男</span>
                                <span v-if="form[item.fields] == '2'">女</span>
                            </div>
                            <div class="label-value" v-else>{{ form[item.fields] }}</div>
                        </div>
                    </el-row>
                    <el-row>
                        <div class="first-footer">
                            本报告非医疗诊断文书，任何医疗行为请遵从医嘱
                        </div>
                    </el-row>
                </el-row>
                <el-row style="page-break-after:always">
                    <div class="two-page">
                        <div class="qianyan">前言</div>
                        <div class="title">
                            尊敬的{{ form.name }}<span v-if="form.sex == '1'">男</span><span
                                v-if="form.sex == '2'">女</span>士：
                        </div>
                        <div class="tw-title-text">您好！</div>
                        <div class="tw-title-text">
                            首先，感谢您对本机构的信赖。我们将竭诚为您提供优质、高效、专业的健康管理服务，为您的健康与幸福保驾护航。
                        </div>
                        <div class="tw-title-text">
                            世界卫生组织（WHO）明确公告：“健康长寿，遗传占15%，社会因素占10%，医疗条件占8%，气候条件占7%，而60%的成份取决于自己的生活方式和态度。
                            ”也就是说，每个人的生命掌握在自己的手中。
                            健康是您最宝贵的财富和资源，健康需要精细的管理。
                        </div>
                        <div class="tw-title-text">
                            健康管理是指对个体和群体的健康进行全面监测、分析、评估、提供健康咨询和指导以及对健康危险因素进行干预的全过程。
                            其宗旨是调动个体、群体及整个社会的积极性，有效地利用有限的资源来 达到最大的健康效果。
                            健康管理的新理念是变人类健康被动管理为主动管理，并帮助人们科学地恢复健康、维护健康、促进健康。
                        </div>
                        <div class="tw-title-text">
                            我们根据您的体检结果及健康问卷调查情况进行汇总分析，对您的健康状况提出综合评估结论和健康干预建议，为您呈上此份个性化健康管理报告。
                        </div>
                        <div class="tw-title-text">
                            由于每个人遗传特质不同，体质差异，生活饮食习惯、运动多少的不同，个人生活调查问卷填写的真实性、准确性、完整性均有不同，这些因素都会对本报告的结果产生影响。同时，此报告内容不可作为诊断和治疗的依据。
                        </div>
                        <div class="tw-title-text">
                            健康从了解自己开始，希望您仔细阅读并妥善保存这份健康报告，关注报告中的提示与建议。
                            由于报告篇幅有限，很多方面的内容并未展示完整，请您登录公众号查看更多详情。
                            如果您对其中内容有任何疑问，可以拨打封底的电话咨询我们，也可以进行在线咨询。
                        </div>
                        <div
                            style="text-align: right;padding-top: 360px;padding-right:60px;font-weight: bold;font-size: 16px">
                            <div>大庆油田总医院健康管理</div>
                            <div>{{ form.reportDate }}</div>
                        </div>
                    </div>
                </el-row>
                <el-row class="three-page twopage" style="page-break-after:always">
                    <div>
                        <div class="molu">目录</div>
                        <div class="title">健康信息摘要 /</div>
                        <el-row>
                            <el-col :span="12" class="col">P01 健康信息汇总</el-col>
                        </el-row>
                        <div class="title">疾病风险评估 /</div>
                        <el-row>
                            <el-col :span="12" class="col">P02 整体健康状况</el-col>
                        </el-row>
                        <div class="title">日常保健建议 /</div>
                        <el-row>
                            <el-col :span="12" class="col">P03 生活方式分析</el-col>
                            <el-col :span="12" class="col">P04 饮食保健建议</el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12" class="col">P05 运动保健建议</el-col>
                        </el-row>
                        <el-row class="footers">
                            <div class="footer">
                                <div class="text-footers">
                                    <div>欢迎登录个人健康中心查询更多信息</div>
                                    <div>公众号： 大庆油田总医院职工健康服务中心</div>
                                    <!-- <div>账号： 2309061087</div>
                                    <div>密码： 123456</div> -->
                                </div>
                            </div>
                        </el-row>
                    </div>
                </el-row>
                <el-row class="four-page twopage" style="page-break-after:always">
                    <div>
                        <div class="molu">健康信息汇总</div>
                        <div class="title">主要健康问题 /</div>
                        <div class="col-docder">
                            <div v-html="form.ZJJL"
                                style="white-space: pre-line;padding: 0 20px 20px 20px;font-size:16px"></div>
                        </div>
                        <div class="title">其他风险因素 /</div>
                        <div class="col-docder">
                            <el-row class="col">
                                <el-col :span="24" class="col-1">
                                    <span>饮食: </span>
                                    <span style="">{{ form.foodsResult ? form.foodsResult.title : '' }}</span>
                                </el-col>
                            </el-row>
                            <el-row class="col">
                                <el-col :span="24" class="col-1">
                                    <span>运动: </span>
                                    <span style="">{{ form.styleResult ? form.styleResult.title : '' }}</span>
                                </el-col>
                            </el-row>
                            <el-row class="col">
                                <el-col :span="24" class="col-1">
                                    <span>吸烟: </span>
                                    <span style="">{{ form.smokeResult ? form.smokeResult.title : '' }}</span>
                                </el-col>
                            </el-row>
                            <el-row class="col">
                                <el-col :span="24" class="col-1">
                                    <span>饮酒: </span>
                                    <span style="">{{ form.drinkResult ? form.drinkResult.title : '' }}</span>
                                </el-col>
                            </el-row>
                            <el-row class="col0">
                                <el-col :span="24" class="col-1">
                                    <span>睡眠: </span>
                                    <span style="">{{ form.sleepResult ? form.sleepResult.title : '' }}</span>
                                </el-col>
                            </el-row>
                            <el-row class="col0">
                                <el-col :span="24" class="col-1">
                                    <span>心理: </span>
                                    <span style="">{{ form.mentalResult ? form.mentalResult.title : '' }}</span>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </el-row>
                <el-row class="five-page twopage" style="page-break-after:always">
                    <div>
                        <div class="molu">整体健康状况</div>
                        <div class="title-text">以下我们将分析您本次的体检结果和健康调查信息，为您做一个综合健康评分，清晰展现危害您健
                            康的主要危险因素,并指出应该达到的目标或相关建议。
                        </div>
                        <div class="title">健康评估结果 /</div>
                        <div style="padding: 10px 20px 20px 20px;">
                            {{ form.pingguResult }}
                        </div>
                        <div class="title">风险人群等级 /</div>
                        <div style="padding: 10px 20px 20px 20px;">
                            {{form.userLevel}}
                            <div v-if="!form.userLevel">未分级</div>
                        </div>
                        <div class="title">风险指标等级 /</div>
                        <div style="padding: 10px 20px 20px 20px;">
                            <div v-for="(e, i) in form.level" :key="i">
                                {{ e }}
                            </div>
                            <div v-if="form.level && form.level.length == 0">未分级</div>
                        </div>

                        <div class="title">综合健康评分 /</div>
                        <div>
                            <el-row :gutter="20">
                                <el-col :span="7">
                                    <div class="zhjkpf">
                                        <div class="zhjkpf-title">您的综合健康评分为</div>
                                        <div class="zhjkpf-count">{{ form.score || 0 }}</div>
                                    </div>
                                </el-col>
                                <el-col :span="17">
                                    <div class="zhjkpf-right">
                                        <div class="zhjkpf-right-tag">
                                            <div class="xt xt00">
                                                <div class="imags" v-if="90 <= form.score"><img
                                                        src="../../../../assets/account.png" /></div>
                                            </div>
                                            <div class="xt xt01">
                                                <div class="imags" v-if="form.score >= 70 && form.score < 90"><img
                                                        src="../../../../assets/account.png" /></div>
                                            </div>
                                            <div class="xt xt02">
                                                <div class="imags" v-if="form.score >= 50 && form.score < 70"><img
                                                        src="../../../../assets/account.png" /></div>
                                            </div>
                                            <div class="xt xt03">
                                                <div class="imags" v-if="form.score >= 30 && form.score < 50"><img
                                                        src="../../../../assets/account.png" /></div>
                                            </div>
                                            <div class="xt xt04">
                                                <div class="imags" v-if="form.score < 30"><img
                                                        src="../../../../assets/account.png" /></div>
                                            </div>
                                        </div>
                                        <div class="zhjkpf-right-xt">
                                            <div class="xt xt01">很差</div>
                                            <div class="xt xt02">差</div>
                                            <div class="xt xt03">较差</div>
                                            <div class="xt xt04">一般</div>
                                            <div class="xt xt05">很好</div>
                                        </div>
                                        <div class="zhjkpf-right-tag">
                                            <div class="xt xt00">90分以上</div>
                                            <div class="xt xt01">70-90 </div>
                                            <div class="xt xt02">50-70</div>
                                            <div class="xt xt03">50-30 </div>
                                            <div class="xt xt04">30分以下</div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                            <div class="zhjkpf-text">
                                评分等级：>90分以上很差 70-90差 50-70较差 50-30一般 30分以下较好
                            </div>
                        </div>
                        <div class="title">主要危险因素 /</div>
                        <div>
                            <table border="0" cellspacing="0" cellpadding="0" width="100%"
                                style="margin: auto !important;">
                                <tbody>
                                    <tr>
                                        <th>类型</th>
                                        <th>危险因素</th>
                                        <th>管理建议</th>
                                    </tr>
                                    <tr v-for="(item, index) in tableData" :key="index">
                                        <td
                                            :class="[index == 0 ? 'zywxys-top' : '', (index + 1) == tableData && tableData.length ? 'zywxys-bottom' : '']">
                                            {{ item.name }}</td>
                                        <td
                                            :class="[index == 0 ? 'zywxys-top' : '', (index + 1) == tableData && tableData.length ? 'zywxys-bottom' : '']">
                                            {{ form[item.ys] ? form[item.ys].title : '' }}</td>
                                        <td
                                            :class="[index == 0 ? 'zywxys-top' : '', (index + 1) == tableData && tableData.length ? 'zywxys-bottom' : '']">
                                            {{ item.gljy }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </el-row>
                <el-row class="six-page twopage" style="page-break-after:always">
                    <div>
                        <div class="title">主要参数 /</div>
                        <div class="col-docder">
                            <el-row class="col-th" style="text-align: center">
                                <el-col :span="6" class="col-1">参数名称</el-col>
                                <el-col :span="4" class="col-1">结果</el-col>
                                <el-col :span="4" class="col-1">单位</el-col>
                                <el-col :span="6" class="col-1">正常参考</el-col>
                                <el-col :span="4" class="col-1">需要注意</el-col>
                            </el-row>
                            <el-row :class="zyList.length == (index + 1) ? 'col0' : 'col'" style="text-align: center"
                                v-for="(item, index) in zyList" :key="index">
                                <el-col :span="6" class="col-1">{{ item.name }}</el-col>
                                <el-col :span="4" class="col-1"> <span> {{ form[item.field] }} &nbsp;</span> </el-col>
                                <el-col :span="4" class="col-1">{{ item.unit }}</el-col>
                                <el-col :span="6" class="col-1">
                                    <div v-if="item.field == 'ua'">
                                        <span v-if="form.sex == '1'">&lt;420μmol/L</span>
                                        <span v-if="form.sex == '2'">&lt;360μmol/L</span>
                                    </div>
                                    <div v-else-if="item.field == 'hdl'">
                                        <span v-if="form.sex == '1'">1.0-1.5mmol/L</span>
                                        <span v-if="form.sex == '2'">1.3-1.5mmol/L</span>
                                    </div>
                                    <span v-else>{{ item.default }}</span>
                                </el-col>
                                <el-col :span="4" class="col-1"> <span> {{ form[`${item.field}Result`] ?
                                    form[`${item.field}Result`].text : '' }} &nbsp;</span>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="title">健康建议 /</div>
                        <div class="title-text">
                            <div v-html="form.YLBJ" style="white-space: pre-line;padding:0 20px;font-size:14px"></div>
                        </div>
                    </div>
                </el-row>
                <el-row class="seven-page twopage" style="page-break-after:always">
                    <div>
                        <div class="molu">生活方式分析</div>
                        <div class="title-text">
                            根据医学研究证实，许多个人行为和生活因素会预示并影响着健康的趋势和寿命。对您目前的生活
                            方式信息进行汇总分析后，生成了如下报告，为您展示了目前的生活方式因素是如何潜在地影响您的身
                            体健康。希望您通过阅读此报告，发现不健康习惯，开始采取行动，控制健康风险。
                        </div>
                        <div class="title">吸烟情况 / {{ form.smokeResult ? form.smokeResult.title : '' }}</div>
                        <div class="title-text">
                            <div v-html="form.smokeResult ? form.smokeResult.summary : ''"
                                style="white-space: pre-line;padding:0 20px;font-size:14px"></div>
                            <div v-html="form.smokeResult ? form.smokeResult.advice : ''"
                                style="white-space: pre-line;padding:0 20px;font-size:14px"></div>
                        </div>
                        <div class="title">饮酒情况 / {{ form.drinkResult ? form.drinkResult.title : '' }}</div>
                        <div class="title-text">
                            <div v-html="form.drinkResult ? form.drinkResult.summary : ''"
                                style="white-space: pre-line;padding:0 20px;font-size:14px"></div>
                            <div v-html="form.drinkResult ? form.drinkResult.advice : ''"
                                style="white-space: pre-line;padding:0 20px;font-size:14px"></div>
                        </div>
                        <div class="title">睡眠情况 / {{ form.sleepResult ? form.sleepResult.title : '' }}</div>
                        <div class="title-text">
                            <div v-html="form.sleepResult ? form.sleepResult.summary : ''"
                                style="white-space: pre-line;padding:0 20px;font-size:14px"></div>
                            <div v-html="form.sleepResult ? form.sleepResult.advice : ''"
                                style="white-space: pre-line;padding:0 20px;font-size:14px"></div>
                        </div>
                        <div class="title">心理情况 / {{ form.mentalResult ? form.mentalResult.title : '' }}</div>
                        <div class="title-text">
                            <div v-html="form.mentalResult ? form.mentalResult.summary : ''"
                                style="white-space: pre-line;padding:0 20px;font-size:14px"></div>
                            <div v-html="form.mentalResult ? form.mentalResult.advice : ''"
                                style="white-space: pre-line;padding:0 20px;font-size:14px"></div>
                        </div>
                    </div>
                </el-row>
                <el-row class="eight-page twopage" style="page-break-after:always">
                    <div>
                        <div class="molu">饮食保健建议</div>
                        <div class="title-text">
                            饮食总原则为食物多样，谷类为主，粗细搭配。多吃蔬菜水果薯类。常吃适量的鱼、禽、蛋和瘦肉
                            等。减少烹调油用量，吃清淡少盐膳食。规律饮食、食不过量，吃新鲜卫生的食物。
                        </div>
                        <div class="title">饮食情况 / </div>
                        <div style="font-size: 14px;padding: 10px 20px">
                            {{ form.foodsResult ? form.foodsResult.title : '' }}
                        </div>
                        <div class="title">膳食方案 /</div>
                        <div style="font-size: 14px;padding: 10px 0">
                            创建日期: {{ form.reportDate }}
                            <div style="float: right">有效期: 3天</div>
                        </div>
                        <div class="col-docder">
                            <el-row class="col-th" style="text-align: center">
                                <el-col :span="4" class="col-1">食谱1 </el-col>
                                <el-col :span="14" class="col-1">食物</el-col>
                                <el-col :span="6" class="col-1">能量 </el-col>
                            </el-row>
                            <el-row :class="zbList.length == (index + 1) ? 'col0' : 'col'" style="text-align: center"
                                v-for="(items, index) in spList" :key="index">
                                <el-col :span="4" class="col-1">{{ items.name }}</el-col>
                                <el-col :span="14" class="col-1">{{ items.sw }}</el-col>
                                <el-col :span="6" class="col-1">
                                    <div>{{ items.nl }}</div>
                                    <div>{{ items.bl }}</div>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="title">饮食注意事项 /</div>
                        <div class="yszysx">
                            1.少食肉、禽类、干豆、龙须菜、鲜豌豆、菠菜、蘑菇等，限制盐的摄入。<br>
                            2.忌食咸蛋、咸肉、咸鱼、酱菜、面酱、腊肠、腌制食物、含盐量不明的调味品。<br>
                            3.宜食用薯类，豆类，蔬菜，水果，富含膳食纤维的食物如大豆，黑色、绿色食品，木耳，香菇等
                            。<br>
                            4.坚持每天吃早餐，不宜过早，误伤肠胃；不宜过于营养，超过消化能力导致胃肠疾病，引起肥胖
                            。<br>
                            5.每人每天盐摄入量不超过6克，盐的摄入量与血压呈正比，摄入过多易导致高血压。<br>
                            6.吃饭前后半小时勿喝水，会稀释唾液和胃液，影响消化吸收，久之会导致身体健康状况不良。<br>
                            7.吃饭不宜过快，进食过快不容易嚼细食物，增加胃的消化负担，影响营养吸收，甚至损伤胃粘膜
                            。<br>
                            8.葱内的蒜辣素可抑制癌细胞的生长，含有微量元素硒，可降低胃液内的亚硝酸盐含量，预防胃癌
                            。<br>
                            9.番茄的酸性很高，可能刺激胃产生更多的胃酸。因此，吃太多番茄会导致泛酸、烧心等症状。<br>
                            10.吃完火锅，建议喝一些茶，这样有助于脂类食物的消化和排出，减少脂类物质在肠道的吸收。
                        </div>
                        <div class="title">食物交换份 /</div>
                        <div class="title-text">
                            食物多样化是《中国居民膳食指南》强调的重点，专家推荐的食谱成分是可以找同类食物代替的。
                            下表列举常见食物产生90千卡热量的食物份量供参考。
                        </div>
                        <div class="swjh">
                            <img src="../../../../assets/swjh.png" />
                        </div>
                    </div>
                </el-row>
                <el-row class="nine-page twopage" style="page-break-after:always">
                    <div>
                        <div class="molu">运动保健建议</div>
                    </div>
                    <div class="title-text">
                        生命在于运动，运动贵在坚持。适当的运动可以控制体重，提高免疫力，还能有效地缓解抑郁和压
                        力。以下根据本次体检及健康调查，对您的运动状况进行分析并定制了运动保健方案。
                    </div>
                    <div class="title">运动情况 / {{ form.styleResult ? form.styleResult.title : '' }}</div>
                    <div class="title-text">
                        <div v-html="form.styleResult ? form.styleResult.summary : ''"
                            style="white-space: pre-line;padding:0 20px;font-size:14px"></div>
                        <div v-html="form.styleResult ? form.styleResult.advice : ''"
                            style="white-space: pre-line;padding:0 20px;font-size:14px"></div>
                    </div>
                    <div class="title">运动注意事项 /</div>
                    <div class="yszysx">
                        1.跑步前先预热身体十分钟，散步、甩臂，拉伸身体各部位的肌肉组织，先慢后快地进行运动。<br>
                        2.运动中不宜过于用力或屏气，不宜做速度强度大、精神紧张的项目；不“省略”整理活动：运动
                        感觉心力俱乏时，应适宜放松，有助于消除疲劳，快速恢复体力；不宜用嘴呼吸，应养成用鼻子呼
                        吸的习惯，使气管和肺部不受尘埃、病菌的侵害。<br>
                        3.运动后，不宜立即蹲坐休息、不在大汗淋漓时洗冷热水浴，沐浴一般应在20分钟内为宜；不贪吃
                        冷饮，不大量喝水吃糖，不立即吃饭；不宜立即吸烟或饮酒；不宜立即走进空调房或风口纳凉，避
                        免打破正常的生理调节机能。<br>
                        4.注意运动安全防护，观察运动场所的周边环境，并采取适当的安全护具；室内运动时，光线要适
                        宜，温度应保持20摄氏度左右，保持良好的通风环境。<br>
                        5.运动要避免受伤，科学锻炼，掌握正确的运动要领，预防运动伤病。<br>
                        6.临睡前不宜过度运动，避免大量出汗，17时至19时是晚间锻炼的最佳时段。<br>
                        7.可携带糖果，若有低血糖反应，可进食少量糖块；若有心绞痛症状，则应立即就医。<br>
                        8.如果有条件，最好在运动前后监测血压和心电图。
                    </div>
                    <div class="title">推荐运动项目 /</div>
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <div class="col-docder">
                                <el-row class="col-th" style="text-align: center">
                                    <el-col :span="14" class="col-1">运动项目 </el-col>
                                    <el-col :span="10" class="col-1">时间（分）</el-col>
                                </el-row>
                                <el-row :class="zbList.length == (index + 1) ? 'col0' : 'col'"
                                    style="text-align: center" v-for="(items, index) in ydList1" :key="index">
                                    <el-col :span="14" class="col-1">{{ items.name }}</el-col>
                                    <el-col :span="10" class="col-1">{{ items.time }}</el-col>
                                </el-row>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="col-docder" v-if="ydList && ydList.length > 0">
                                <el-row class="col-th" style="text-align: center">
                                    <el-col :span="14" class="col-1">运动项目 </el-col>
                                    <el-col :span="10" class="col-1">时间（分）</el-col>
                                </el-row>
                                <el-row :class="ydList.length == (index + 1) ? 'col0' : 'col'"
                                    style="text-align: center" v-for="(items, index) in ydList" :key="index">
                                    <el-col :span="14" class="col-1">{{ items.name }}</el-col>
                                    <el-col :span="10" class="col-1">{{ items.time }}</el-col>
                                </el-row>
                            </div>
                        </el-col>
                    </el-row>
                    <div class="pgjg-title-text">
                        【注】图中所示项目为优先推荐项目，列表中为备选项目。时间指相当于推荐运动量的运动时间。请根据个人喜好
                        和条件选择合适的运动项目
                    </div>
                </el-row>
                <el-row style="page-break-after:always">
                    <div class="two-page">
                        <div class="qianyan">结束语</div>
                        <div class="tw-title-text">
                            《健康中国2030规划纲要》强调“健康是促进人的全面发展的必然要求，是经济社会发展的基础
                            条件，是国家富强、民族振兴的重要标志”。

                        </div>
                        <div class="tw-title-text">
                            影响健康的因素很多，包括遗传、日常生活习惯、社会环境、医疗资源等等，其中医疗资源,仅占
                            影响健康因素的8%，最大的影响因素还是日常生活习惯及社会环境。世界卫生组织的数据显示，75%的
                            癌症是已知的环境因素和不良习惯所引起，而这些因素都是可以预防的，例如抽烟、酗酒。
                        </div>
                        <div class="tw-title-text">
                            很多人把钱投入治疗上，特别是临终前的抢救上，这些治疗费用占整个医疗费用的70%以上。但是
                            收益率最高的健康投资却是参加健康管理，控制各自的健康危险因素，降低疾病风险，优先预防疾病。
                        </div>
                        <div class="tw-title-text">
                            而健康检查不仅可以使健康人群加深对自我身体机能的了解，改变不良生活习惯，避免导致疾病
                            的危险因子产生，更重要的是可以帮助人们科学地了解和维护健康，最大限度地降低疾病的困扰与经济
                            负担。
                        </div>
                        <div class="tw-title-text">
                            我们愿以卓越的服务、优质的技术继续为您提供医疗保健服务，从体检中发现健康问题，从保健
                            中解决健康问题。我们衷心祝愿您身体更加健康，希望能与您携手朝着健康的目标共同迈进。

                        </div>
                        <div class="tw-title-text">
                            最后，感谢您对我们的支持，别忘了定期为自己和家人安排健康检查。
                        </div>
                    </div>
                </el-row>
            </div>
        </div>
        <div class="buttons">
            <el-button class="btn" @click="handleClose()">取 消</el-button>
            <el-button class="btn" type="primary" @click="handelprint">打印报告</el-button>
        </div>
    </div>
    <!--    </el-dialog>-->
</template>

<script>
import pgjgBar from './pgjgBar'
import shfsPie from './shfsPie'
export default {
    components: {
        pgjgBar,
        shfsPie
    },
    props: {
        visible: {
            default: false
        },
        form: {
            default: {}
        },
    },
    data() {
        return {
            print: {
                id: 'printTest',
            },
            printFooter: null,
            ydList: [
                { name: "擦窗户", time: "63" },
                { name: "逛街", time: "70" },
                { name: "滑雪", time: "21" },
                { name: "轮滑旱冰", time: "25" },
                { name: "慢跑", time: "25" },
            ],
            ydList1: [
                { name: "爬山（上山5.5km/h）", time: "29" },
                { name: "上楼", time: "22" },
                { name: "少林拳", time: "15" },
                { name: "射箭", time: "45" },
                { name: "手洗衣服", time: "53" },
            ],
            spList: [
                { name: "早餐", sw: "烤馒头(馒头)，草莓杏仁奶(草莓、杏仁、牛乳)，甜拌白菜 心(大白菜、胡萝卜（红）)", nl: "479.5kcal", "bl": "24.9%" },
                { name: "午餐", sw: "蚝油生菜(生菜（牛俐）)，鱿鱼茶树菇(鱿鱼（水浸）、茶   树菇（干）)，赤小豆玉米饭(赤小豆、稻米)，家常豆腐(豆 腐（北豆腐）、辣椒（红，尖，干）)", nl: "954.22kcal", "bl": "49.6%" },
                {
                    name: "晚餐", sw: "蘑菇焖牛肉(蘑菇（鲜蘑）、牛肉（肥瘦）、番茄、小葱\n" +
                        ")，瓜烧白菜(黄鱼（大黄花鱼）、大白菜（小白口）、小麦 粉（标准粉）、鸭蛋)，红枣燕麦黑米粥(枣（干）、黑米、 枸杞菜)\n", nl: "491.9kcal", "bl": "25.5%"
                },
                { name: "成分分析", sw: "能量:1925.6kcal    碳水化合物:302.6g   脂肪:40.9g    蛋白质:102.2g" },
            ],
            tableData: [
                { name: "家族史", ys: "parentsResult", gljy: "注意防患相关疾病" },
                { name: "饮食是否规律", ys: "foodsResult", gljy: "应规律饮食，避免饮食过量或消化不良" },
                { name: "运动", ys: "styleResult", gljy: "保证足够的运动频率和运动时间" },
                { name: "吸烟情况", ys: "smokeResult", gljy: "戒烟，以保护呼吸道和肺" },
                { name: "睡眠", ys: "sleepResult", gljy: "保持良好睡眠习惯" },
                { name: "心理", ys: "mentalResult", gljy: "放松心情，避免压力过大" },
            ],
            zbList: [
                { name: "收缩压（高压）", unit: "mmHg", field: 'sbp', default: '' },
                { name: "舒张压（低压）", unit: "mmHg", field: 'dbp', default: '' },
                { name: "体重指数", unit: "kg/m^2", field: 'bmi', default: '' },
                { name: "总胆固醇", unit: "mmol/L", field: 'chol', default: '' },
                { name: "低密度脂蛋白胆固醇", unit: "mmol/L", field: 'ldl', default: '' },
                { name: "高密度脂蛋白胆固醇", unit: "mmol/L", field: 'hdl', default: '' },
                { name: "同型半胱氨酸", unit: "μmol/L", field: 'hcy', default: '' },
                { name: "空腹血糖", unit: "mmol/L", field: 'fbg', default: '' },
                { name: "血尿酸", unit: "μmol/L", field: 'ua', default: '' },
            ],
            zyList: [
                { name: "收缩压（高压）", unit: "mmHg", field: 'sbp', default: '90-139mmHg' },
                { name: "舒张压（低压）", unit: "mmHg", field: 'dbp', default: '60-89mmHg' },
                { name: "体重指数", unit: "kg/m^2", field: 'bmi', default: '18.5-23.9' },
                { name: "总胆固醇", unit: "mmol/L", field: 'chol', default: '<5.2mmol/L' },
                { name: "低密度脂蛋白", unit: "mmol/L", field: 'ldl', default: '<3.37mmol/L' },
                { name: "高密度脂蛋白", unit: "mmol/L", field: 'hdl', default: '1.03-2.07mmol/L' },
                { name: "同型半胱氨酸", unit: "μmol/L", field: 'hcy', default: '5-15μmol/L ' },
                { name: "空腹血糖", unit: "mmol/L", field: 'fbg', default: '3.9-6.1mmol/L' },
                { name: "血尿酸", unit: "μmol/L", field: 'ua', default: '' },
            ],
            ysList: [
                { name: "谷薯", mqsrl: "2~<4碗", jysrl: "3.25碗/6.5两/325克", jy: "继续保持" },
                { name: "肉类", mqsrl: "1~<2两", jysrl: "1~<2两", jy: "继续保持" },
                { name: "鱼虾", mqsrl: "1~<2两", jysrl: "1~<2两", jy: "继续保持" },
                { name: "蛋类", mqsrl: "吃", jysrl: "1个/45克", jy: "继续保持" },
                { name: "奶类", mqsrl: "<1杯", jysrl: "2杯/400ml", jy: "适量增加" },
                { name: "豆类", mqsrl: "0.5~<1两", jysrl: "0.6两/30克", jy: "继续保持" },
                { name: "蔬菜", mqsrl: "2~<6两", jysrl: "8两/400克", jy: "适量增加" },
                { name: "水果", mqsrl: "1~<4两", jysrl: "5.5两/275克", jy: "适量增加" },
                { name: "饮水", mqsrl: "1~<2杯", jysrl: "9~<12杯", jy: "适量增加" },
            ],
            jbxxi: [
                {
                    label: "姓名",
                    fields: "name"
                },
                {
                    label: "性别",
                    fields: "sex"
                },
                {
                    label: "年龄",
                    fields: "age"
                },
                {
                    label: "单位",
                    fields: "companyName"
                },
                {
                    label: "评估日期",
                    fields: "reportDate"
                },
                {
                    label: "评估人",
                    fields: "guardian"
                },
            ],
        }
    },
    filters: {
        filterLeve: function (a) {
            const levelMap = {
                "一级": 1,
                "二级": 2,
                "三级": 3
            };
            if(!a) return
            let highestLevelItem = a.reduce((max, item) => {
                const currentLevelValue = levelMap[item.split(":")[1]]; // 当前元素等级值
                const maxLevelValue = levelMap[max.split(":")[1]] || 0; // 最大等级值，如果max未定义则为0

                return currentLevelValue > maxLevelValue ? item : max;
            });
            if (highestLevelItem && highestLevelItem.includes(":")) {
                return highestLevelItem.split(":")[1]; // 从"22:三级"获取"三级"
            }
            return highestLevelItem;
        }
    },
    methods: {
        handelprint() {
            this.$print(this.$refs.print)
        },
        handleClose() {
            this.$emit('close')
        },
    }
}
</script>

<style lang="scss" scoped>
table {
    border-collapse: collapse;
}

table tbody tr {
    line-height: normal !important;
    font-size: 14px;
}

table tbody td {
    padding: 10px;
    border: 1px solid #ebebeb;
    text-align: center;
}

table tbody th {
    padding: 10px;
    border-top: 1px solid #67C23A;
    text-align: center;
}

.text-title {
    font-weight: bold;
    font-size: 55px;
    text-align: center;
    color: black;
    padding-top: 200px;
    padding-bottom: 100px;
}

.item-jbxx {
    padding-top: 80px;

    .item-list {
        margin-left: auto;
        margin-right: auto;
        width: 400px;
        padding: 10px 0;
        display: flex;
        color: black;
        font-size: 15px;

        .label {
            width: 80px;
            font-weight: bold;
            padding-right: 8px;
            color: black;
        }

        .label-value {
            text-align: center;
            height: 20px;
            font-size: 14px;
            width: 100%;
            border-bottom: 1px solid black;
        }
    }

}

.first-footer {
    padding-top: 280px;
    text-align: center;
}

.two-page {
    /*display: flex;*/
    align-items: center;
    color: black;
    padding-top: 50px;
    line-height: 23px;
    font-size: 15px;

    .tw-title-text {
        padding: 2px 0px;
        text-indent: 40px;
    }

    .qianyan {
        padding-bottom: 20px;
        text-align: center;
        font-size: 30px;
        font-weight: bold
    }
}

.twopage {
    font-size: 18px;

    .col-th {
        border-top: 1px solid #67C23A;
        border-bottom: 1px solid #67C23A;
        padding: 10px;
    }

    .col-docder {
        border-bottom: 1px solid #67C23A;
    }

    .molu {
        font-weight: bold;
        text-align: center;
        font-size: 40px;
        color: #67C23A;
        padding-top: 60px;
        padding-bottom: 30px;
    }

    .title {
        color: #67C23A;
        padding: 20px 10px 10px;
    }

    .title-text {
        line-height: 25px;
        font-size: 15px;
        padding-bottom: 20px;
    }

    .col0 {
        font-size: 15px;
        font-weight: bold;
        padding: 10px;
        border-right: 1px solid #ebebeb;
        border-left: 1px solid #ebebeb;
    }
}

.three-page {
    .col {
        font-size: 17px;
        padding: 2px 10px;
    }

    .footers {
        margin-top: 450px;
        display: flex;
        justify-content: center;
        font-size: 14px;

        .footer {
            .text-footers {
                margin: 10px;
                padding: 15px;
                border: 1px dotted #67C23A;
            }

            text-align: center;
            border: 1px solid #67C23A;
            width: 380px;

            div {
                padding: 2px;
            }
        }
    }
}

.four-page {
    .col {
        font-size: 15px;
        font-weight: bold;
        padding: 10px;
        border-bottom: 1px solid #ebebeb;
        border-right: 1px solid #ebebeb;
        border-left: 1px solid #ebebeb;
    }

}

.five-page {
    .col-docder {
        border-top: 1px solid #67C23A;
        border-bottom: 1px solid #67C23A;
    }

    .zhjkpf {
        font-size: 12px;
        text-align: center;
        background-color: #ebebeb;
        padding: 30px;

        .zhjkpf-title {
            padding-top: 15px;
        }

        .zhjkpf-count {
            color: #67C23A;
            font-size: 25px;
            padding: 5px;
        }
    }

    .zhjkpf-right {
        border: 1px solid #ebebeb;
        padding: 10px;

        .imags {
            margin-top: 32px;
            display: flex;
            justify-content: center;

            img {
                width: 30px;
            }
        }

        .zhjkpf-right-tag {
            display: flex;
            width: 100%;
            font-size: 14px;

            .xt {
                width: 20%;
                text-align: center;
            }

            .xt01 {
                text-align: center;
                padding-left: 10px;
            }

            .xt02 {
                text-align: center;
            }

            .xt03 {
                text-align: center;
                padding-right: 20px;
            }

            .xt04 {
                text-align: center;
                padding-right: 10px;
            }

            .xt05 {
                text-align: center;
            }
        }

        .zhjkpf-right-xt {
            display: flex;
            width: 100%;
            font-size: 14px;

            .xt {
                width: 20%;
                text-align: center;
                color: #f7f7f7;
                padding: 5px 0;
            }

            .xt01 {
                background-color: #ca1213;
            }

            .xt02 {
                background-color: #f86531;
            }

            .xt03 {
                background-color: #ffb83a;
            }

            .xt04 {
                background-color: #6fba2c;
            }

            .xt05 {
                background-color: #359927;
            }
        }
    }

    .zhjkpf-text {
        border: 1px solid #ebebeb;
        padding: 10px;
        font-size: 14px;
        margin-top: 20px;
    }

    .zywxys-top {
        border-top: 1px solid #67C23A;
    }

    .zywxys-bottom {
        border-bottom: 1px solid #67C23A;
    }

}

.six-page {
    .pgjg-bar {
        border: 1px solid #ebebeb;
        padding: 10px;
    }

    .col {
        font-size: 15px;
        font-weight: bold;
        padding: 10px;
        border-bottom: 1px solid #ebebeb;
        border-right: 1px solid #ebebeb;
        border-left: 1px solid #ebebeb;
    }

    .zhjkpf-right {
        border: 1px solid #ebebeb;
        padding: 10px;

        .imags {
            margin-top: 22px;
            display: flex;
            justify-content: center;

            img {
                width: 30px;
            }
        }

        .zhjkpf-right-tag {
            display: flex;
            width: 100%;
            font-size: 14px;

            .xt {
                width: 25%;
            }

            .xt01 {
                text-align: left;
                padding-left: 20px;
            }

            .xt02 {
                text-align: center;
            }

            .xt03 {
                text-align: right;
                padding-right: 10px;
            }

            .xt04 {
                text-align: right;
            }
        }

        .zhjkpf-right-xt {
            display: flex;
            width: 100%;
            font-size: 14px;

            .xt {
                width: 25%;
                text-align: center;
                color: #f7f7f7;
                padding: 5px 0;
            }

            .xt01 {
                background-color: #6fba2c;
            }

            .xt02 {
                background-color: #ffb83a;
            }

            .xt03 {
                background-color: #f86531;
            }

            .xt04 {
                background-color: #ca1213;

            }
        }
    }

    .pgjg-col {
        font-size: 14px;

        ::v-deep .el-col {
            padding: 10px 0;
            border: 1px solid #ebebeb;
        }

        ::v-deep .el-col-4 {
            text-align: center;
        }

        ::v-deep .el-col-8 {
            background-color: #ebebeb;
        }
    }

    .pgjg-title-text {
        font-size: 12px;
        color: #4f9fe7;
        padding: 10px 0;
    }
}

.seven-page {
    .shfs {
        display: flex;
        align-items: center;
    }

    .shfs-title {
        padding: 10px;
        font-size: 15px;
    }

    .shfs-text {
        border: 1px solid #ebebeb;
        padding: 10px;
        font-size: 15px;
    }

    .zyfxjy {
        margin-right: 40px;
        font-size: 14px;

        .zyfxjy-title {
            color: #67C23A;
            padding: 10px 0;
        }

        .zyfxjy-title-text {
            background-color: #ebebeb;
            padding: 10px;

            .zyfxjy-small-title {
                font-weight: bold;
                padding-bottom: 10px;
            }

            .zyfxjy-text {
                line-height: 20px;
            }
        }

    }
}

.eight-page {
    .shfs {
        display: flex;
        align-items: center;

        .label {
            padding-right: 10px;
            font-weight: bold;
        }

        .value {
            padding: 0 10px;
        }
    }

    .shfs-title {
        padding: 10px;
        font-size: 15px;
    }

    .col {
        font-size: 15px;
        font-weight: bold;
        padding: 10px;
        border-bottom: 1px solid #ebebeb;
        border-right: 1px solid #ebebeb;
        border-left: 1px solid #ebebeb;
    }

    .pgjg-title-text {
        font-size: 12px;
        color: #4f9fe7;
        padding: 10px 0;
        display: flex;
        align-items: center;
    }

    .ysxgfx {
        margin: 3px;
        text-align: center;
        background-color: #ebebeb;
        font-size: 14px;
        padding: 8px 20px;
        display: flex;
        align-items: center;

        img {
            padding-right: 5px;
        }
    }

    .yszysx {
        font-size: 14px;
        line-height: 25px;
        border: 1px solid #67C23A;
        padding: 10px 20px;
    }

    .swjh {
        img {
            width: 700px;
        }
    }
}

.nine-page {
    .shfs {
        font-size: 14px;
        display: flex;
        align-items: center;

        .label {
            width: 130px;
            font-weight: bold;
        }

        .value {
            padding: 0 5px;
            font-size: 13px;
        }
    }

    .shfs-title {
        padding: 10px;
        font-size: 15px;
        display: flex;
    }

    .col {
        font-size: 15px;
        font-weight: bold;
        padding: 10px;
        border-bottom: 1px solid #ebebeb;
        border-right: 1px solid #ebebeb;
        border-left: 1px solid #ebebeb;
    }

    .yszysx {
        font-size: 14px;
        line-height: 25px;
        border: 1px solid #67C23A;
        padding: 10px 20px;
    }

    .zhjkpf-right {
        padding: 10px 10px 250px 0;

        .zhjkpf-right-tag {
            display: flex;
            width: 100%;
            font-size: 14px;

            .xt {
                width: 14.28%;
            }

            .xt01 {
                text-align: left;
                padding-left: 20px;
            }

            .xt02 {
                text-align: left;
                padding-left: 20px;
            }

            .xt03 {
                text-align: center;
                padding-right: 40px;
            }

            .xt04 {
                text-align: center;
                padding-right: 30px;
            }

            .xt05 {
                text-align: center;
            }

            .xt06 {
                text-align: right;
                padding-right: 10px;
            }

            .xt07 {
                text-align: right;
            }
        }

        .zhjkpf-right-xt {
            display: flex;
            width: 100%;
            font-size: 14px;

            .xt {
                width: 14.28%;
                text-align: center;
                color: #f7f7f7;
                padding: 5px 0;
            }

            .xt01 {
                background-color: #359927;
            }

            .xt02 {
                background-color: #6fba2c;
            }

            .xt03 {
                background-color: #dcdf9f;
            }

            .xt04 {
                background-color: #ffb83a;
            }

            .xt05 {
                background-color: #f88518;
            }

            .xt06 {
                background-color: #f86531;
            }

            .xt07 {
                background-color: #ca1213;
            }
        }

    }

    .pgjg-title-text {
        font-size: 12px;
        color: #4f9fe7;
        padding: 10px 0;
    }
}

.page-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1.5px solid black;
    text-align: center;
    z-index: 2;
    /* 底部内容的层叠顺序较低于页面内容 */
}

@media print {
    @page {
        margin-bottom: 30px;
        /* 在每一页的四周留出30px的边距 */
    }

    .echarts-container {
        width: 100% !important;
        height: auto !important;
    }
}
</style>
